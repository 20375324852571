export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'parent_id',
    width: '300px'
  },
  {
    text: 'Улица',
    align: 'left',
    sortable: true,
    value: 'name',
    width: '300px'
  },
  {
    text: 'Подтверждена',
    align: 'left',
    sortable: true,
    value: 'is_verified',
    width: '300px'
  }
]
