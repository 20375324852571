<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1"
          multi-sort
          :footer-props="{itemsPerPageOptions:  [20,100,300]}"
        >
          <template v-slot:item="{ item }">
            <tr
              :class="classByStatus(item)"
              @click="detail(item.parent_id)"
            >
              <td>{{item.parent_id}}</td>
              <td>{{item.name}}</td>
              <td>{{item.is_verified ? 'Да' : 'Нет'}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import table from '@/components/mixins/table'

export default {
  mixins: [table],
  name: 'Table',
  data: () => ({
    headers: headers
  }),
  methods: {
    classByStatus (item) {
      let className = ''
      className = item.is_verified ? '' : 'not-verified'
      if (this.opened.includes(item.parent_id)) {
        className += ' selected-item'
      }
      return className
    }
  }
}
</script>

<style scoped>

</style>
