<template>
  <v-container
    fluid
    class="pt-3 pb-0"
  >
    <v-row class="text-left">
      <v-col
        cols="8"
        class="text-left pt-0 pb-0"
      >
        <div class="d-flex justify-end align-start">
          <Autocomplete
            :value="filters.streetFilter"
            @input="localFilters.streetFilter = $event"
            item-value="streetId"
            item-text="name"
            placeholder="поиск по названию улицы"
            prepend-icon="mdi-database-search"
            url="/street/autocomplete"
            :chars-to-search="2"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Selects from '../../common/selects'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import controls from '@/components/mixins/controls/controls'

export default {
  mixins: [controls],
  name: 'Controls',
  components: { Autocomplete },
  data: () => ({
    isVerifiedOptions: Selects.isVerified,
    localFilters: {
      isVerified: null,
      streetFilter: null
    }
  })
}
</script>

<style scoped>

</style>
